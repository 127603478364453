import { Container, Card, Button } from "react-bootstrap";
import { useEffect } from "react";
import ReactGA from "react-ga";

const OpenSubmissionCard = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <div>
            <Container
                style={{ marginTop: '1em', marginBottom: '1em' }}
                className={"text-center"}>
                <Card>
                    <Card.Body>
                        <Card.Title><b>Don't see a listing that reflects your skillset?</b></Card.Title>
                        <Card.Subtitle>Submit your resume for future openings</Card.Subtitle>
                        <br />
                        <Button variant="outline-secondary" style={{ width: '50%', marginTop: '1rem' }} href="mailto:info@caliola.com">
                            Apply Now
                        </Button>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default OpenSubmissionCard;
