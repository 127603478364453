import { Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Carrier from "../../images/NavyComms.svg";
import React from "react";

const SectionOne = () => {
    return (
        <Row style={{ marginTop: '5rem' }}>
            <hr />
            <Col style={{ marginTop: '5rem' }} className={'text-center'} xs={12} sm={12} md={6} lg={6} xl={6}>
                <h4>Providing Trusted Solutions for Mission-Critical Communications.</h4>
            </Col>
            <Col style={{ marginTop: '5rem' }} xs={12} sm={12} md={6} lg={6} xl={6} className={'text-center'}>
                <Row>
                    <Col style={{ color: 'grey' }}>
                        <b>
                            <h5>Caliola is a 100% Woman Owned Small Business Headquartered in Colorado Springs,
                                CO.</h5>
                        </b>
                        <div style={{ marginTop: '8rem', marginBottom: '2rem' }}>
                            <Link to={"/ContactUs"}>
                                <Button variant={"outline-dark"}>Contact Us</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col style={{ color: 'grey' }} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <b>
                            <h5>Caliola Engineering's Team of Systems, Security, and Software Engineers Identify and
                                Solve Mission-Critical Problems for Customers Across the US Government.</h5>
                        </b>
                    </Col>
                </Row>
            </Col>
            <Image src={Carrier} style={{ marginTop: '5rem' }} />
        </Row>
    )
}

export default SectionOne;
