const bios = [
    {
      "Name": "Jennifer Halford",
      "Title": "CEO & President",
      "ImageLink": require("../../images/jennifer.jpg"),
      "Education": [
        "UCLA Anderson School of Business, M.B.A., 2013",
        "University of California at Los Angeles (UCLA), M.Sc. in Computer Science, 2001",
        "UCLA, B.Sc. in Computer Science and B.A. in Business Economics, 1999"
      ],
      "Summary": "Jennifer Halford founded Caliola Engineering in 2019 following a nearly 20-year career in the defense industry. Her exceptional leadership was recognized in 2014 by Aviation Week as one of the “Top 40 Under 40” rising stars of the aerospace industry. The following year, The Aerospace Corporation named Jennifer their “Woman of the Year.”  Using the same qualities that won those accolades, strong technical leadership and a smart and fearless approach to business, Jennifer created Caliola, a company that would quickly be recognized for its impact on the industry and Colorado. In less than two years, Caliola grew from a startup to a second stage company which raised over $17 million. This impressive beginning resulted in Caliola Engineering winning “Colorado Company to Watch” in 2021. Building upon this early success, Jennifer strives to provide innovative solutions for secure and assured communication.  \nUCLA Anderson School of Business, M.B.A., 2013\nUCLA, M.Sc. in Computer Science, 2001\nUCLA, B.Sc. in Computer Science and B.A. in Business Economics, 1999\n"
    },
    {
      "Name": "Dr. Tom Halford",
      "ImageLink": require("../../images/tom.jpg"),
      "Title": "Chief Scientist ",
      "Education": [
        "University of Southern California (USC), Ph.D. in Electrical Engineering, 2007",
        "Simon Fraser University, B.A.Sc. in Engineering Physics, 2002"
      ],
      "Summary": "Dr. Halford is a “full stack” communications engineer whose research interests span all theoretical and practical aspects of secure communications. He is the inventor of OverKey and has led its development since 2019. He is Trained as a coding theorist, he has worked on problems in information-theoretic security, cryptography, and mesh and mobile ad hoc networking (MANET). Before co-founding Caliola, Dr. Halford worked with TrellisWare Technologies, Inc., where his responsibilities included sales and business development for their modern forward error correction (FEC) coding products. Dr. Halford became a Certified Information Systems Security Professional (CISSP) in 2015 and a Project Management Professional (PMP) in 2016. He has served on the Technical Program Committee (TPC) for the Military Communications Conference (MILCOM) since 2011."
    },
    {
      "Name": "Ryan Biondo",
      "ImageLink": require("../../images/ryan.png"),
      "Title": "Principal Engineer / SATCOM Subject Matter Expert",
      "Education": [
        "Johns Hopkins University, M.Sc. in Electrical and Computer Engineering, 2008",
        "University of Maryland College Park, B.Sc. in Electrical Engineering, 2004"
      ],
      "Summary": "Ryan has over sixteen years of practical engineering experience supporting the DoD and its contractors. He is a globally recognized subject matter expert in the tactical and strategic operations of the Milstar and AEHF SATCOM systems. He is also a recognized expert on cryptographic operations and the Cryptographic Modernization Initiative (CMI) with the NSA. He’s provided direct support to US Strategic Command, US Space Command, USSF, the USSF Space and Missiles System Center (SMC), and the White House Communications Agency (WHCA). Ryan became a Certified Systems Engineering Professional (CSEP) through the International Council on Systems Engineering (INCOSE) in 2010 and a CISSP in 2012."
    }
  ]
export default bios