import { Container, Card, Button } from "react-bootstrap";
import data from "../jsonFiles/jobs.json";
import { useEffect } from "react";
import ReactGA from "react-ga";

const JobCard = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (

        <div>

            {Array.from(data.data).map((data, key) => {
                return (

                    <Container
                        style={{ marginTop: '1em', marginBottom: '1em' }}
                        className={"text-center"}>
                        <Card>
                            <Card.Body>
                                <Card.Title><b>{data.Title}</b></Card.Title>
                                <Card.Subtitle>{data.Location}</Card.Subtitle>
                                <br />
                                <Button variant="outline-secondary" style={{ width: '50%', marginTop: '1rem' }} href={data.JobDescriptionLinkCS} target="_blank">
                                    Apply Now
                                </Button>
                            </Card.Body>
                        </Card>
                    </Container>
                )

            })}

        </div>

    )
}

export default JobCard;