import JobCard from "./JobCard";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import joinUs from "../../images/JoinUs.svg";
import OpenSubmissionCard from "./OpenSubmissionCard";

const JoinUs = () => {
    return (
        <>
            <Container>
                <Row style={{ marginTop: '10rem' }}>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 style={{ marginBottom: '5rem' }} className={'text-center'}>
                            Provide Trusted Solutions for Mission-Critical Communications.
                        </h3>
                    </Col>
                    <Col
                        xs={12} sm={12} md={6} lg={6} xl={6}
                        style={{ marginBottom: '5rem' }} className={'text-center'}>
                        <h3>I3 for C3 – Insight, Innovation, and Integrity for Command, Control,
                            and Communications.</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <Image
                                src={joinUs}
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '5rem' }}>
                    <hr />
                    <Col style={{ marginTop: '5rem' }} className={'text-center'}>
                        <h1>Open Positions</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className={'text-center'}>
                        <h4 style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                            Joining Caliola will give you the opportunity to learn from experienced technical leaders in
                            the field, as well as defense domain experts with decades of knowledge and broad access
                        </h4>
                    </Col>
                    <hr />
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className={'text-center'}>
                        <h4 style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                            At Caliola Engineering, we are a women owned small business building exciting and innovative
                            products. Our dedicated team of engineers and business experts create superior technical
                            products and services to enable assured,
                            secure communications for austere environments.
                        </h4>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row style={{ marginTop: '5rem' }}>
                    <hr />
                    <Col></Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} className={'text-center'}>
                        <h4 style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                            This is an opportunity to join a rapidly-growing and inclusive team that is solving tough
                            problems. Caliola offers a flexible, collaborative, and open culture that is unique in the
                            defense industry. We offer generous benefits, including subsidized health insurance and
                            401(k) matching, and a scalable, competitive pay structure.
                        </h4>
                    </Col>
                </Row>
                <Row style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                    <Col>
                        <JobCard />
                        <OpenSubmissionCard />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default JoinUs;
