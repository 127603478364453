import { Col, Container, Row, } from "react-bootstrap";
import React from "react";

const Contracts = () => {
    return (
        <div>
            <Container>
                <Row style={{ marginTop: '10rem', marginBottom: '5rem' }}>
                    <Col>
                        <h1 style={{ fontSize: '5vw' }}>Contracting With Caliola</h1>
                        <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }} className={'text-left'}>
                            <a href="https://www.gsa.gov/buy-through-us/products-services/professional-services/buy-services/oasis-and-oasis-small-business">One Acquisition Solution for Integrated Services</a>
                        </h4>
                        <hr />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '5rem' }}>
                    <Col>
                        <h1>↳ OASIS Overview</h1>
                        <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }}>
                            One Acquisition Solution for Integrated Services –
                            Small Business (OASIS SB) is designed to address
                            agencies’ need for a full range of service
                            requirements that integrate multiple professional
                            service disciplines and ancillary services/products
                            with the flexibility for all contract types and
                            pricing at the task order level.
                            <br />
                            <br />
                            One Acquisition Solution for Integrated Services –
                            Unrestricted (OASIS Unrestricted) is designed to
                            address agencies’ need for a full range of service
                            requirements that integrate multiple professional
                            service disciplines and ancillary services/products
                            with the flexibility for all contract types and
                            pricing at the task order level.
                        </h4>
                        <hr />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '5rem' }}>
                    <Col>
                        <h1>↳ Contractor Capabilities for OASIS</h1>
                        <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }}>
                            Caliola is a GSA OASIS Small Business (SB) Pool 1
                            and 3 Prime contract award holder. The services
                            provided under OASIS are intended to meet the
                            professional service mission requirements of all
                            Federal agencies, including all organizations
                            within Department of Defense and National Security
                            Community.
                        </h4>
                        <h2 style={{ marginLeft: '2rem', marginTop: '2rem' }}>Details</h2>
                        <h4 style={{ marginLeft: '2rem' }}>
                            <ul>
                                <li>Span many areas of expertise and mission space</li>
                                <li>Span multiple professional service disciplines</li>
                                <li>Allow flexibility for all contract types, including hybrids and cost-reimbursement, at the task order level</li>
                                <li>Allow ancillary support components, commonly referred to as Other Direct Costs (ODC), at the task order level</li>
                            </ul>
                        </h4>
                        <h2 style={{ marginLeft: '2rem', marginTop: '2rem' }}>Core Disciplines</h2>
                        <h4 style={{ marginLeft: '2rem' }}>
                            <ul>
                                <li>Program management services</li>
                                <li>Management consulting services</li>
                                <li>Logistics services</li>
                                <li>Engineering services</li>
                                <li>Scientific services</li>
                                <li>Financial services</li>
                            </ul>
                        </h4>
                        <h2 style={{ marginLeft: '2rem', marginTop: '2rem' }}>Featured</h2>
                        <h4 style={{ marginLeft: '2rem' }}>
                            <ul>
                                <li>Commercial and non-commercial services</li>
                                <li>CONUS and OCONUS requirements</li>
                                <li>Classified and unclassified services</li>
                                <li>Ancillary Support necessary to deliver a total solution, such as:</li>
                            </ul>
                        </h4>
                        <h4 style={{ marginLeft: '4rem', marginTop: '-1rem', marginBottom: '-1rem' }}>
                            <ul>
                                <li>Labor</li>
                                <li>Materials</li>
                                <li>ODCs</li>
                                <li>Hybrid of any of the above</li>
                            </ul>
                        </h4>
                        <h4 style={{ marginLeft: '2rem' }}>
                            <ul>
                                <li>
                                    Designated &nbsp;
                                    <a href="https://www.gsa.gov/buying-selling/category-management/bestinclass">Best-in-Class</a> &nbsp;
                                    (BIC) solution helping agencies meet their spend under management goals
                                </li>
                                <li>
                                    Download this &nbsp;
                                    <a href="https://www.gsa.gov/cdnstatic/PROFESSIONAL SERVICES_OASIS_OASIS_SB_7_26_2018 (1)-1533149444.pdf">OASIS BIC profile</a>
                                </li>
                            </ul>
                            Review the &nbsp;
                            <a href="https://www.gsa.gov/buying-selling/products-services/professional-services/buy-services/oasis/oasis-contracts">OASIS and OASIS SB</a> &nbsp;
                            Master Contracts.
                        </h4>
                        <hr />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '5rem' }}>
                    <Col>
                        <h1>↳ Contractor Key Personnel POC</h1>
                        <h2 style={{ marginLeft: '2rem' }}>Jennifer Halford, CEO</h2>
                        <h4 style={{ marginLeft: '2rem' }}>
                            <a href="mailto: jennifer.halford@caliola.com">jennifer.halford@caliola.com</a>
                        </h4>
                        <hr />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '5rem' }}>
                    <Col>
                        <h1>↳ OASIS Small Business Contracts</h1>
                        <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }}>
                            <a href="/static/media/OASISSB_Pool_1_Contract.946010d8.pdf" target="_blank">OASIS SB Pool 1</a> <br />
                            <a href="/static/media/OASISSB_Pool_3_Contract.e37fa794.pdf" target="_blank">OASIS SB Pool 3</a>
                        </h4>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>OASIS SB Pool 1 Contract #: 47QRAD20D1123</h1>
                        <h1>OASIS SB Pool 3 Contract #: 47QRAD20D3126</h1>
                        <h1>UEI: Q9DSGGYNU8J9</h1>
                        <h1>CAGE Code: 8DPM1</h1>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Contracts;
