import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import sat from "../../images/SatCom.jpg";
import ReactGA from "react-ga";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";

const Home = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <Container>
            <Row style={{ marginTop: '5rem' }}>
                <Col xs={12} sm={12} md={12} lg={12} xxl={12}>
                    <h1 style={{ fontSize: '6vw' }}>Insight.</h1>
                    <h1 style={{ fontSize: '6vw' }}>Innovation.</h1>
                    <h1 style={{ fontSize: '6vw' }}>Integrity.</h1>
                </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
                <Col
                    style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <Image
                        src={sat}
                        style={{ width: '100%', height: 'auto' }}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
                <hr />
                <Col xs={0} sm={0} md={6} lg={6} xl={6}>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h4
                        style={{ marginTop: '5rem' }}
                        className={'text-center'}>
                        Caliola provides innovative technical solutions and services for secure and resilient communications. We have expertise in military satellite communications (SATCOM); modernized High Frequency (HF) communications; automated SATCOM planning; non-traditional cybersecurity architectures; and assured position, navigation, and timing (PNT).
                    </h4>
                </Col>
            </Row>
            <SectionOne />
            <SectionTwo />
        </Container>
    )
}

export default Home;