import { Row, Col } from "react-bootstrap";
import React from "react";

const SectionTwo = () => {
    return (
        <>
            <Row style={{ marginTop: '5rem' }}>
                <hr />
                <Col style={{ marginTop: '5rem' }} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h1 style={{ fontSize: '7vw' }}>Technology.</h1>
                </Col>
            </Row>
            <Row style={{ marginTop: '5rem', marginLeft: '2rem' }}>
                <hr />
                <Col style={{ marginTop: '5rem' }} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h4>↳
                        <a href={"/technology/#OverKey"} style={{ color: 'black' }}> OverKey®</a>
                    </h4>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginTop: '5rem' }}>
                    <h4>
                        OverKey® is the only mesh VPN designed specifically to meet the needs of USG customers.
                    </h4>
                </Col>
            </Row>
            <Row style={{ marginTop: '5rem', marginLeft: '2rem' }}>
                <hr />
                <Col style={{ marginTop: '5rem' }} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h4>↳
                        <a href={"/technology/#AssuredConf"} style={{ color: 'black' }}>
                            AssuredConf
                        </a>
                    </h4>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginTop: '5rem' }}>
                    <h4>
                        AssuredConf™ is Caliola’s framework for AI-enabled, automated communications planning.
                    </h4>
                </Col>
            </Row>
            <Row style={{ marginTop: '5rem', marginLeft: '2rem', marginBottom: '5rem' }}>
                <hr />
                <Col style={{ marginTop: '5rem' }} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h4>↳ <a href={"/technology#SCAPP"} style={{ color: 'black' }}>
                        SCAPP</a></h4>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginTop: '5rem' }}>
                    <h4>
                        SCAPP is an alternate PNT solution that uses communication satellite signals for positioning when GPS is denied.
                    </h4>
                </Col>
            </Row>
            <Row style={{ marginLeft: '2rem', marginBottom: '5rem' }}>
                <hr />
                <Col style={{ marginTop: '5rem' }} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h4>EdgeSync™</h4>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginTop: '5rem' }}>
                    <h4>
                        EdgeSync™ is an OverKey-based distributed file system that we are developing for the Air Force to enable bandwidth-efficient and secure peer-to-peer file sharing in contested environments.
                    </h4>
                </Col>
            </Row>
            <Row style={{ marginLeft: '2rem', marginBottom: '5rem' }}>
                <hr />
                <Col style={{ marginTop: '5rem' }} xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h4>RippliPHY™</h4>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginTop: '5rem' }}>
                    <h4>
                        RippliPHY™ is patent-pending signal processing technology that radically reduces the peak-to-average power (PAPR) of multicarrier signals in the High Frequency (HF) band. RippliPHY allows HF systems to achieve higher data rates, lower probabilities of detection, or increased reliability.
                    </h4>
                </Col>
            </Row>
        </>
    )
}

export default SectionTwo;
