import { Col, Container, Row } from "react-bootstrap";
import bios from "../jsonFiles/teamBios.js";
import TeamCard from "./TeamCard";
import React from "react";

const Team = () => {

    return (
        <>
            <Container style={{ marginTop: '10rem', marginBottom: '5rem' }}>
                
                <Row style={{ marginTop: '10rem', marginBottom: '5rem' }}>
                    <Col>
                        <h1 style={{ fontSize: '5vw' }}>Leadership Team</h1>
                        <hr />
                    </Col>
                </Row>

                <Row>
                    {Array.from(bios).map((value, key) => {
                        return (
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} key={key}>
                                <TeamCard
                                    ImageLink={value["ImageLink"]}
                                    Name={value["Name"]}
                                    Title={value["Title"]}
                                    Summary={value["Summary"]}
                                    Education={value["Education"]}
                                />
                            </Col>
                        )
                    })}
                </Row>

            </Container>
        </>
    )

}

export default Team;