import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import ReactGA from "react-ga";

const News = (props) => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <div style={{ marginBottom: '3em' }}>
            <Row style={{ marginTop: '5vh' }}>
                <hr />
                <Col style={{ marginTop: '5vh' }}>
                    <h1>↳ <a href={props.href} style={{
                        color: 'black'
                    }}>{props.Title}</a>
                    </h1>
                    <h4 style={{ marginLeft: '2rem' }}>
                        {props.Text}
                    </h4>
                </Col>
            </Row>
        </div>
    )
}

export default News;