import { Container, Col, Row, Image } from "react-bootstrap";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import wplLogo from "../../images/206_WPL_Logo.jpg";

const WPL = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <Container>
            <Row style={{ textAlign: 'center' }}>
                <Col style={{ marginTop: '10rem', marginBottom: '2rem' }}>
                    <Image
                        src={wplLogo}
                        style={{ width: "80%", maxWidth: '400px', height: "auto" }}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{ marginTop: '10rem', marginBottom: '2rem' }}>
                    <h1 style={{ fontSize: '5vw' }}>WPL, Inc. Overview</h1>
                    <hr />
                    <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }} className={'text-left'}>
                        Founded in 1978, WPL, Inc. provides quality Systems, Security, and Software engineering services to DoD and civilian customers.
                    </h4>
                    <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }} className={'text-left'}>
                        For the past 40+ years, WPL has provided quality systems engineering services to clients across the United States. WPL is a California based small business comprised of highly qualified engineers and analysts, with offices in Manhattan Beach, CA and Colorado Springs, CO.
                    </h4>
                    <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }} className={'text-left'}>
                        WPL’s veteran staff provides communication systems engineering services for a variety of technical projects within the Dept of Defense.
                    </h4>
                </Col>
                <hr />
            </Row>
            <Row>
                <Col style={{ marginTop: '5rem', marginBottom: '2rem' }}>
                    <h1 style={{ fontSize: '5vw' }}>History</h1>
                    <hr />
                    <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }} className={'text-left'}>
                        Warner P. Lombardi originally founded as WPL Consultants in January of 1977, as a sole proprietorship.  In June 1978 WPL was incorporated, becoming WPL, Inc. and began providing performance oriented software and systems engineering services to defense and aerospace clients.  WPL received its first contract in 1977 from Hughes Aircraft Company, Ground Systems Group - providing engineering support to perform design, code, checkout, integration, and documentation of spacecraft sensor software.                    </h4>
                    <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }} className={'text-left'}>
                        In the more than four decades since that first contract award, WPL has maintained its high standard for engineering services and is now one of the most respected engineering firms in the Space, Ground and Control Systems community (MILSATCOM).
                    </h4>
                </Col>
            </Row>
        </Container >
    )
}

export default WPL;
