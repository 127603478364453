import { Container } from "react-bootstrap";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import OverKeyDesc from "./OverKeyDesc";
import AssuredConfDesc from "./AssuredConfDesc";
import SCAPPDesc from "./SCAPPDesc";
import Header from "./Header";

const Technology = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <Container>
            <Header />
            <OverKeyDesc />
            <AssuredConfDesc />
            <SCAPPDesc />
        </Container>
    )
}

export default Technology;
