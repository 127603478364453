import React from "react";
import { Card, Row, Col } from "react-bootstrap"

const TeamCard = (props) => {
    return (
        <div>
            <Card style={{
                minWidth: '18rem', minHeight: "85rem", marginTop: '1rem', maxWidth: '35rem'
            }}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={"text-center"}>
                        <div>
                            <Card.Img style={{
                                objectFit: 'cover',
                                filter: 'grayscale(100%)',
                                width: '100%',
                                objectPosition: '20% 10%',
                                zoom: '170%',
                                maxWidth: '100%',
                                maxHeight: '275px',
                                minHeight: '18rem',
                                height: '60%',
                            }}
                                src={props.ImageLink} />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className={"text-center"}>
                        <br />
                        <Card.Title style={{ fontSize: '2em' }}>{props.Name}</Card.Title>
                        <Card.Subtitle style={{ color: "#2f517a", fontSize: '1em' }}>
                            {props.Title}
                        </Card.Subtitle>
                        <br />
                        <Card.Text style={{ display: 'flex', textAlign: 'left', margin: '2em' }} className={"text-center"}>
                            {props.Summary}
                        </Card.Text>
                        <p style={{
                            fontSize: "1em",
                            fontStyle: "italic",
                            textAlign: 'left',
                            margin: '2em',
                            color: '#2b6d99'
                        }}
                            className={"text-center"}
                        >
                            {props.Education.map((data, key) => {
                                return <p>{data}</p>
                            })}
                        </p>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default TeamCard;
