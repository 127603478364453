import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Container } from "react-bootstrap";
import Header from "./components/Header";
import Home from "./components/HOME/Home";
import Technology from "./components/TECHNOLOGY/Technology";
import News from "./components/News";
import ContactUs from "./components/ContactUs";
import Contracts from "./components/CONTRACTS/Contracts";
import data from "./components/jsonFiles/news.json";
import ReactGA from 'react-ga';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import React from "react";
import JoinUs from "./components/JOIN_US/JoinUs";
import Team from "./components/TEAM/Team";
import Footer from "./components/Footer";
import WPL from './components/WPL/WPL';

ReactGA.initialize('UA-209923026-1');


function App() {

    return (

        <Router>
            <div className="App">
                <Header />
                <Routes>
                    <Route exact path={"/"} element={<Home />} />
                    <Route path={"/Team"} element={<Team />} />
                    <Route path={"/Technology"} element={<Technology />} />
                    <Route path={"/News"} element={<Container>
                        <Row>
                            <Col style={{ marginTop: '10rem', marginBottom: '2rem' }}>
                                <h1 style={{ fontSize: '5vw' }}>News</h1>
                            </Col>
                        </Row>
                        <Row>
                            {Array.from(data.news).map((value, key) => {
                                return (
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} key={key}>
                                        <News
                                            Image={value["Image"]}
                                            Title={value["Title"]}
                                            Text={value["Text"]}
                                            href={value["href"]}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                    </Container>}>
                    </Route>
                    <Route path={"/ContactUs"} element={<ContactUs />} />
                    <Route path={"/JoinUs"} element={<JoinUs />} />
                    <Route path={"/Contracts"} element={<Contracts />} />
                    <Route path={"/WPL"} element={<WPL />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
