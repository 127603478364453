import { Col, Row } from "react-bootstrap";
import React from "react";

const Header = () => {
    return (
        <Row>
            <Col style={{ marginTop: '10rem', marginBottom: '2rem' }}>
                <h1 style={{ fontSize: '5vw' }}>Technology</h1>
                <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }} className={'text-left'}>
                    Caliola is world class encryption, communications planning, satellite communications, and
                    mesh network technologies.
                    Caliola advances research in encryption, networks, satellite communication, and machine
                    learning.
                </h4>
            </Col>
        </Row>
    )
}

export default Header;
