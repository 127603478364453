import { Col, Image, Row } from "react-bootstrap";
import SCAPP from "../../images/SCAPP.svg";
import React from "react";


const SCAPPDesc = () => {
    return (
        <Row style={{ marginTop: '5rem', marginBottom: '5rem' }}>
            <hr />
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginTop: '5rem' }}>
                <div id="SCAPP">
                    <Image
                        src={SCAPP}
                        style={{ width: '100%', height: 'auto' }}
                        rounded
                    />
                </div>
            </Col>
            <Col style={{ marginTop: '5rem' }} xs={12} sm={12} md={6} lg={6} xl={6}>
                <h1>↳ SCAPP</h1>
                <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }}>
                    SCAPP is an alternate PNT solution that uses communication satellite signals for positioning when GPS is denied. Unlike competing solutions which can only operate if GPS is denied locally, SCAPP can continue to operate even if the entire GPS constellation is compromised on orbit.
                </h4>
            </Col>
        </Row>
    )
}

export default SCAPPDesc;
