import { Col, Image, Row } from "react-bootstrap";
import NavyComms from "../../images/AssuredConfLogo.svg";
import React from "react";

const AssuredConfDesc = () => {
    return (
        <Row style={{ marginTop: '5rem' }}>
            <hr />
            <Col style={{ marginTop: '5rem' }}>
                <div id={"AssuredConf"}>
                    <Image
                        src={NavyComms}
                        style={{ width: '100%', height: 'auto' }}
                        rounded
                    />
                </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginTop: '5rem' }}>
                <h1>↳ AssuredConf</h1>
                <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }}>
                    AssuredConf is a communications planning tool that translates the commander’s intent into a
                    resilient network architecture that can rapidly reconfigure and dynamically reconstitute in
                    response to changing risks and constraints within the environment. While currently being
                    tailored for Navy use, AssuredConf can automate communications planning for any DoD
                    organization, as well as other organizations operating in dynamic environments with high demand
                    and limited capabilities.
                </h4>
            </Col>
        </Row>
    )
}

export default AssuredConfDesc;
