import { Col, Container, Image, Row } from "react-bootstrap";
import React from "react";
import banner from "../images/CaliHorizontal.png";

const ContactUs = () => {
    return (
        <div>
            <Row style={{ marginTop: '10rem', marginBottom: '5rem' }}>
                <Col style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <Image
                        src={banner}
                        style={{ width: '80%', height: 'auto' }}
                    />
                </Col>
            </Row>
            <Container>
                <Row style={{ marginTop: '10rem', marginBottom: '5rem' }}>
                    <Col>
                        <h1 style={{ fontSize: '5vw' }}>Contact Us</h1>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col style={{ marginTop: '1rem', marginBottom: '5rem' }}>
                        <h1>Jennifer Halford, CEO</h1>
                        <h4>
                            <a href="mailto: jennifer.halford@caliola.com">jennifer.halford@caliola.com</a>
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <hr />
                    <Col style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                        <h1>CAGE Code: 8DPM1</h1>
                        <h1>UEI: Q9DSGGYNU8J9</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '5rem' }}>
                    <Col>
                        <h1>↳ Locations</h1>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginTop: '1rem', marginBottom: '5rem' }}>
                        <h1>Colorado Springs</h1>
                        <h4>Colorado</h4>
                    </Col>
                </Row>
                <Row>
                    <hr />
                    <Col style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                        <h1>Denver</h1>
                        <h4>Colorado</h4>
                    </Col>
                </Row>
                <Row>
                    <hr />
                    <Col style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                        <h1>Huntsville</h1>
                        <h4>Alabama</h4>
                    </Col>
                </Row>

                <Row>
                    <hr />
                    <Col style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                        <h1>San Diego</h1>
                        <h4>California</h4>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactUs;
