import { Container, Row, Col, Image } from "react-bootstrap";
import caliolaLogo from "../images/Caliola.svg";
import React from "react";

const Footer = () => {
    return (
        <Container>
            <hr style={{ color: 'black' }} />
            <Row style={{ marginBottom: '5rem', marginTop: '10rem' }}>
                <Col xs={6} sm={5} md={5} xl={4} xxl={4}>
                    <Image
                        src={caliolaLogo}
                        style={{ width: '30%', height: 'auto' }}
                    />
                    <div style={{ marginTop: '2rem', color: 'grey' }}>
                        <h5>
                            Providing Trusted Solutions for
                        </h5>
                        <h5>
                            Mission-Critical Communications.
                        </h5>
                    </div>
                </Col>
                <Col xs={4} sm={4} md={4} xl={4} xxl={4}>
                    <h5 >
                        Explore
                    </h5>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }} >
                        <div style={{ marginTop: '2rem' }} >
                            <li><a href={"/"} style={{ color: 'black', textDecoration: 'none' }}>Home</a></li>
                            <li><a href={"/Technology"} style={{ color: 'black', textDecoration: 'none' }}>Technology</a></li>
                            <li><a href={"/News"} style={{ color: 'black', textDecoration: 'none' }}>News</a></li>
                            <li><a href={"/ContactUS"} style={{ color: 'black', textDecoration: 'none' }}>Contact Us</a></li>
                            <li><a href={"/Team"} style={{ color: 'black', textDecoration: 'none' }}>Our Team</a></li>
                            <li><a href={"/JoinUs"} style={{ color: 'black', textDecoration: 'none' }}>Join Us</a></li>
                            <li><a href={"/Contracts"} style={{ color: 'black', textDecoration: 'none' }}>Contracts</a></li>
                        </div>
                    </ul>
                </Col>
                <Col xs={3} sm={3} md={3} xl={4} xxl={4}>
                    <h5>
                        Follow
                    </h5>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        <div style={{ marginTop: '2rem' }}>
                            <li><a href={"https://www.linkedin.com/company/caliola"} style={{ color: 'black', textDecoration: 'none' }}>LinkedIn</a></li>
                        </div>
                        <div style={{ marginTop: '2rem' }}>
                            <li><a href={"https://www.facebook.com/people/Caliola-Engineering-LLC/100068614479860/"} style={{ color: 'black', textDecoration: 'none' }}>Facebook</a></li>
                        </div>
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer;
