import { Button, Col, Image, Row } from "react-bootstrap";
import OverKey from "../../images/OverKey.svg";
import React from "react";

const OverKeyDesc = () => {
    return (
        <Row>
            <hr />
            <Col style={{ marginTop: '5rem' }}>
                <div id="OverKey">
                    <h1>↳ OverKey®</h1>
                    <h4 style={{ marginLeft: '2rem', marginTop: '2rem' }}>
                        OverKey builds on top of NSA approved protocols and can secure peer-to-peer and multicast traffic. Caliola is developing OverKey-based solutions to secure swarming munitions and pLEO SATCOM.
                    </h4>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            marginTop: '2rem'

                        }}>
                        <Button
                            href={"https://www.youtube.com/watch?v=L5hd2vuk0hk"}
                            variant={'secondary'}
                        >See More</Button>
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginTop: '5rem' }}>
                <Image
                    src={OverKey}
                    style={{ width: '100%', height: 'auto' }}
                />
            </Col>
        </Row>
    )

}

export default OverKeyDesc;
