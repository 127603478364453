import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import caliola from "../images/Caliola.svg"

const Header = () => {
    return (
        <>
            <div>
                <Navbar bg="light" expand="lg" fixed="top">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Navbar.Brand href="/">
                                    <img
                                        src={caliola}
                                        width="70px"
                                        height="40px"
                                        className="d-inline-block align-top"
                                        alt="React Bootstrap logo"
                                    />
                                </Navbar.Brand>
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/Technology">Technology</Nav.Link>
                                <Nav.Link href="/News">News</Nav.Link>
                                <Nav.Link href="/Contracts">Contracts</Nav.Link>
                                <Nav.Link href="/ContactUs">Contact Us</Nav.Link>
                                <Nav.Link href="/Team">Our Team</Nav.Link>
                                <Nav.Link href="/wpl">WPL</Nav.Link>
                                <Nav.Link href="/JoinUs">Join Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default Header;
